<template>
  <b-modal
    id="modal-vehicle"
    ref="refModal"
    :title="t('Phương tiện')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="!isLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <!-- <avatar-view align="center" /> -->
            <image-view
              v-model="itemLocal.imageFile"
              align="center"
              :src="itemLocal.image"
              :width="100"
              :height="100"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="type"
              rules="required"
            >
              <select-vehicle-type
                v-model="itemLocal.type"
                required
                :default-value="itemLocal.type"
                :label="t('Loại phương tiện')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                :label="t('Tên dòng xe')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Tên dòng xe') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder="Honda Airblade"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="color"
              rules="required"
            >
              <b-form-group
                :label="t('Màu xe')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Màu xe') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="color"
                  v-model="itemLocal.color"
                  placeholder="Đen nhám"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="licensePlate"
              rules="required"
            >
              <b-form-group
                :label="t('Biển số xe')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Biển số xe') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="licensePlate"
                  v-model="itemLocal.licensePlate"
                  placeholder="29T2-686.68"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="ownerName"
              rules="required"
            >
              <b-form-group
                :label="t('Tên chủ xe(theo đăng ký xe)')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Tên chủ xe(theo đăng ký xe)') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="ownerName"
                  v-model="itemLocal.ownerName"
                  placeholder="Nguyễn Văn A"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="ticketNumber"
              rules=""
            >
              <b-form-group
                :label="t('Số vé xe')"
              >
                <b-form-input
                  id="ticketNumber"
                  v-model="itemLocal.ticketNumber"
                  placeholder="No.19"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="apartment"
              rules=""
            >
              <select-apartment
                v-model="itemLocal.apartment"
                :filter-active="true"
                :default-value="itemLocal.apartment"
                :label="t('Tòa nhà')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="room"
              rules=""
            >
              <select-room
                v-model="itemLocal.room"
                :filter-active="true"
                :default-value="itemLocal.room"
                :label="t('Phòng')"
                :apartment="itemLocal.apartment"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="tenant"
              rules=""
            >
              <select-tenant
                v-model="itemLocal.tenant"
                :default-value="itemLocal.tenant"
                :label="t('Khách hàng')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                :apartment="itemLocal.apartment"
                :room="itemLocal.room"
              />
            </validation-provider>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectVehicleType from '@/views/components/SelectVehicleType.vue';
import SelectTenant from '@/views/components/SelectTenant.vue';
import ImageView from '@/views/components/ImageView.vue';
import useVehicleModal from './useVehicleModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,

    SelectApartment,
    SelectRoom,
    SelectVehicleType,
    SelectTenant,
    ImageView,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      t,
      onSubmit,
      isLoading,
      onOpen,
    } = useVehicleModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      t,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      isLoading,
      onOpen,
    };
  },
};
</script>
