import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVehicles(ctx, params) {
      return useJwt.getVehicles(params).then(response => response);
    },
    exportVehicles(ctx, params) {
      return useJwt.exportVehicles(params).then(response => response);
    },
    generateImportVehicleTemplate(ctx, params) {
      return useJwt.generateImportVehicleTemplate(params).then(response => response);
    },
    createVehicle(ctx, data) {
      return useJwt.createVehicle(data).then(response => response);
    },
    updateVehicle(ctx, data) {
      return useJwt.updateVehicle(data).then(response => response);
    },
    importVehicles(ctx, data) {
      return useJwt.importVehicles(data).then(response => response);
    },
    getVehicleDetail(ctx, id) {
      return useJwt.getVehicleDetail(id).then(response => response);
    },
    deleteVehicles(ctx, data) {
      return useJwt.deleteVehicles(data).then(response => response);
    },
  },
};
