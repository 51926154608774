<template>
  <b-modal
    id="modal-vehicle-detail"
    ref="refModalVehicleDetail"
    :title="t('Thông tin phương tiện')"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <b-list-group class="">
        <b-list-group-item v-if="itemLocal.image">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Hình ảnh xe') }}:
            </b-col>
            <b-col class="">
              <avatar-view
                :src="itemLocal.image"
              />
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.type">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Loại xe') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.type.title }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Biển số xe') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.licensePlate }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tên dòng xe') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.color">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Màu sắc') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.color }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.ticketNumber">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Số vé xe') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.ticketNumber }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.ownerName">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tên chủ xe') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.ownerName }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.apartment">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tòa nhà') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.apartment.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.room">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Phòng') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.room.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.tenant">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Khách hàng') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.tenant.name }}
            </b-col>
          </b-row>
        </b-list-group-item>

      </b-list-group>

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import AvatarView from '@/views/components/AvatarView.vue';
import useVehicleDetailModal from './useVehicleDetailModal';

export default {
  components: {
    BModal,
    BSpinner,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,

    AvatarView,
  },
  props: {
    vehicleId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      t,
    } = useVehicleDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      t,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
