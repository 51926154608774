import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import vehicleStoreModule from '../vehicleStoreModule';

export default function useVehicleModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'vehicle';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, vehicleStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const isLoading = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };
  const { t } = useI18nUtils();
  const resetModal = () => {
    resetItemLocal();
  };

  const itemId = computed(() => (props.item && props.item.id ? props.item.id : null));
  const fetchDetailData = () => {
    if (itemId.value) {
      isLoading.value = true;
      store.dispatch('vehicle/getVehicleDetail', itemId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };
  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const formData = new FormData();
    formData.append('name', itemLocal.value.name);
    formData.append('color', itemLocal.value.color);
    formData.append('licensePlate', itemLocal.value.licensePlate);
    formData.append('ticketNumber', itemLocal.value.ticketNumber);
    formData.append('ownerName', itemLocal.value.ownerName);

    if (itemLocal.value.apartment && itemLocal.value.apartment.id > 0) {
      formData.append('apartmentId', itemLocal.value.apartment.id);
    }
    if (itemLocal.value.room && itemLocal.value.room.id > 0) {
      formData.append('roomId', itemLocal.value.room.id);
    }
    if (itemLocal.value.tenant && itemLocal.value.tenant.id > 0) {
      formData.append('tenantId', itemLocal.value.tenant.id);
    }
    if (itemLocal.value.type) {
      formData.append('typeId', itemLocal.value.type.value);
    }

    if (itemLocal.value.imageFile) {
      formData.append('image', itemLocal.value.imageFile);
    }

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      formData.append('id', itemLocal.value.id);
      store
        .dispatch('vehicle/updateVehicle', { data: formData, id: itemLocal.value.id })
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('vehicle/createVehicle', formData)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
    t,
    isLoading,
    onOpen,
  };
}
